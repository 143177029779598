
import {computed, defineComponent, onMounted, ref} from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {setCurrentPageBreadcrumbs, setCurrentPageTitle} from "@/core/helpers/breadcrumb";



export default defineComponent({
  name: "overview",
  components: {
  },
  props: {
    accountID: String,
  },
  setup(props) {


    onMounted(() => {


      setTimeout(() => {
        setCheckedMarkets()
      }, 3000);
    });


    const submitButtonMarkets = ref<HTMLElement | null>(null);

    const arrCheckedMarkets = ref([]);
    const arrCheckedMarkets2 = ref([]);

    const myDetails = ref ({});
    store.dispatch(Actions.ACCOUNT_DETAILS, {ID: props.accountID}).then((prmData) => {
      myDetails.value = prmData.data;
    });

    function setCheckedMarkets(){
      if(JSON.parse((myDetails.value as any).Markets)!==undefined && JSON.parse((myDetails.value as any).Markets)!==null){
        arrCheckedMarkets2.value = JSON.parse((myDetails.value as any).Markets);
        console.log("setCheckedMarkets " + arrCheckedMarkets2.value);
      }


    }
    function isMarketExist(prmMarketID) {
      if(arrCheckedMarkets2.value!==null){
        for (let i=0; i<arrCheckedMarkets2.value.length; i++){
          let each = arrCheckedMarkets2.value[i];
          if(each===prmMarketID){
            return true;
          }
        }
        return false;
      }

    }


    function getCheckedIDs(){
      const vvClassName = "inputMarketsSelect";
      arrCheckedMarkets.value=[];
      const arrElements = document.getElementsByClassName(vvClassName);
      for (let i=0; i<arrElements.length; i++){
        let eachElemnt = arrElements[i] as HTMLInputElement;

        //console.log(eachElemnt.getAttribute("reservationID") + " " + eachElemnt.checked + " " + eachElemnt.getAttribute("accountID"));
        if (eachElemnt.checked) {
          arrCheckedMarkets.value.push(eachElemnt.getAttribute("marketID"));
        }
      }
      console.log("Account MArketts  " + JSON.stringify(arrCheckedMarkets.value));

      return arrCheckedMarkets.value;
    }



    store.dispatch(Actions.MARKETS_LIST_CACHE, {});
    const myMarkets = computed(() => {
      return store.getters.MarketsListCache;
    });

    const saveChangesMarkets = () => {
      if (submitButtonMarkets.value) {
        submitButtonMarkets.value.setAttribute("data-kt-indicator", "on");
        const payloadMarkets = {
          ID: props.accountID,
          //Markets: (typeof newItemData.value.itemMarket !="string") ? (JSON.stringify(newItemData.value.itemMarket)).replace(/\\/g, '') : '""',
          Markets: JSON.stringify(arrCheckedMarkets.value),
        }
        console.log(payloadMarkets);
        store.dispatch(Actions.ACCOUNT_EDIT, payloadMarkets)
            .then(() => {
              Swal.fire({
                text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Geri Dön!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                // Go to page after successfully login
                //router.push({name: "userlist"});
              });
              submitButtonMarkets.value?.removeAttribute("data-kt-indicator");
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getAccountErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Tekrar dene!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
              submitButtonMarkets.value?.removeAttribute("data-kt-indicator");
            });

      }
    };





    return {
      submitButtonMarkets,
      saveChangesMarkets,
      myMarkets,
      getCheckedIDs,
      myDetails,
      arrCheckedMarkets2,
      isMarketExist,
    };


  },
});
